//layout

// ラッパー
#wrapper{
	width: 100%;min-width: 1000px;
}

// ヘッダー
header{
	width: 100%;height: 70px;overflow: hidden;background: #fff;
	.logo{
		padding:17px 0 0 15px;float: left;
	}
}

// グローバルナビ
#menu{
	width: calc(100% - 400px);float: right;position: relative;
}
#menu-btn{display: none;}
.navi-wrap{
	height: 70px;
}
#gnavi{
	position: absolute;right:180px;
	li{float: left;padding-top: 10px;margin-right: 35px;}
	a{@extend .shingoM;display: block;position: relative;
		color: $BLUE;font-size: 1.4rem;line-height: 50px;height: 50px;
		&:before{
			content:"";width: 100%;height: 2px;background: $BLUE;position: absolute;bottom:0;left:0;opacity: 0;
			transition: bottom 0.2s $ease7,opacity 0.2s $ease7;
		}
	}
	.active a,a:hover{
		text-decoration: none;
		&:before{bottom:3px;opacity: 1;}
	}
}
.head-contact{
	display: inline-block;position: absolute;right:0;
	a{display: block;padding: 0 20px 0 55px;line-height: 70px;height: 70px;background: $BLUE;color: #fff;
		@extend .shingoM;font-size: 1.6rem;	position: relative;transition: background 0.4s $ease7,color 0.4s $ease7;
		&:before{font-size: 2.8rem;position: absolute;left:20px;line-height: 70px;}
		@include hover{
			background: $GREEN;text-decoration: none;
		}
	}
}
.win{
 	&.chrome{
	 	#gnavi,.head-contact{
	 		a{transform: rotate(0.05deg);}
		}	
 	}
 	&.firefox,&.edge{
	 	#gnavi,.head-contact{
	 		a{transform: rotate(0.001deg);}
		}	
 	}
 	&.chrome,&.firefox{
 		#gnavi a:before{transition: none;}
 	}
}
 	
 
article{
	
}

.main{

}


// フッター
footer{
	.banner{
		padding: 80px 0;
		ul{width: 960px;margin: 0 auto;display: flex;justify-content: space-between;}a
		a{display: block;}
	}
	/*.foot-privacy{
		width: 960px;margin: 0 auto 10px;//font-size: 1.2rem;
		li{display: inline-block;}
		a{color: $BLACK;font-weight: bold;
			&:before{font-size:1.2rem;}
		}
	}*/
	.footer-wrap{
		width: 100%;background: url(../images/bg_footer.jpg) center center no-repeat;background-size:cover;
	}
	.footer-box{
		width: 960px;margin: 0 auto;padding: 130px 0;color: #fff;
		display: flex;
		a{color: #fff;}
	}
	.foot-address{
		width: 400px;font-size: 1.4rem;padding: 35px 0 15px;
		h3{font-size: 1.8rem;font-weight: bold;margin-bottom: 20px;}
		p{margin-bottom: 10px;}		
	}
	.foot-siteindex{overflow: hidden;padding-left: 50px;padding-top: 25px;
		ul{width:250px;float: left;}
		.first{font-size: 1.4rem;margin-top: 12px;}
		.second{font-size: 1.3rem;}
	}
	
	.copyright-wrap{
		background: #122633;padding: 28px 30px;font-size: 1.2rem;line-height: 1;overflow: hidden;
	}
	.foot-navi{
		float: left;
		li{display: inline-block;margin-right: 35px;}
		a{color: #fff;}
	}
	.copyright{
		color: #ccc;float: right;
	}
	.pagetop{
		position: fixed;right:0px;bottom:-40px;z-index: 10;transition: bottom 0.5s $ease1;
		&.show{bottom:40px;}
		a{display: block;width: 40px;height: 40px;overflow: hidden;text-indent: -9999px;position: relative;
			&:before{
				display: block;width: 40px;text-align: center;
				font-size: 2.6rem;transform: rotate(-90deg);color: $BLUE;position: absolute;text-indent: 0;top:8px;left:0;
				transition: top 0.2s $ease1;}
			@include hover{
				text-decoration: none;
				&:before{top:0;}
			}
		}
	}
}
