//mobile
body{
	font-size: 1.4rem;
}
.xs{font-size: 1.0rem;}
.s{font-size: 1.2rem;}
.l{font-size: 1.6rem;}

.fl-left,.fl-right{
	width: 100%;min-width: 100%;float: none;margin: 0 0 20px !important;
}
.btn{
	padding-right: 40px;
	&.btn-big{
		font-size: 1.4rem;padding:12px 45px 12px 12px;
		&:before{top:12px;right:12px;}
	}
}
table{font-size: 1.4rem;
	&:not(.nobreak){
		tr,th,td{display: block;border: none;padding: 0;width: 100%;}
		tr{border-bottom: 1px dotted $BDC;padding-bottom: 14px;margin-bottom: 14px;
			&:last-child{border: none;}
		}
		th{margin-bottom: 3px;}
		tbody th{width: 100%;}
	}
}

.block{
	padding-top: 50px;margin-bottom: 50px;
}

.page-body{
	h2{font-size: 2.4rem;padding-top: 15px;
		.s{font-size: 1.4rem;display: inline-block;padding-top: 5px;}
	}
	h3{font-size: 1.9rem;padding-top: 10px;}
	h4{font-size: 1.6rem;}
}


.page-title-wrap{height: 160px;
	&:before{width: 100%;transform: skew(0);left:0;}
}
.page-title{
	top:50% !important;transform: translateY(-50%);font-size: 3.0rem !important;
}
#message .page-title-wrap:after{background-position: center center;}
#introdution{
	.page-title-wrap:after{background-position: center center;}
	.page-title{font-size: 2.8rem !important;}
}


.breadcrumb{display: none;}

input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{font-size: 1.4rem;}
form{
	#sei,#mei,#seikana,#meikana{width: 46%;}
	#sei,#seikana{margin-right: 3%;}
	#tel input{margin-right: 2%;}
	#btn-submit{
		width: 90%;padding: 15px 0;margin-top: 20px;
	}
	#back{width:70%;font-size: 1.3rem;padding: 10px 0;}
}

.page-navi{
	width: 100%;
	.page-navi-list{
		font-size: 1.2rem;justify-content: center;padding: 0px 10px;border-bottom: 1px solid $BDC;
		li{
			padding: 0 18px !important;border: none !important;
		}
	}
}

header{
	height: 60px;
	.logo{padding: 18px 0 0 10px;
		img{width: 240px;}
	}
}

#menu-btn{
	right:5px;top:5px;
}
#gnavi{
	a{font-size: 1.4rem;}
}


footer{
	.banner{
		padding: 60px 5% 45px;
		li{width: 46%;margin-bottom: 15px;}
		img{@include max(w);}
	}
	.foot-address{
		text-align: center;
		h3{font-size: 1.6rem;}
		p{font-size: 1.2rem;}
	}
	.globalsign{
		position: static;
	}
}


/* sliderバージョン */
.key{
	height: 330px;
	.bx-pager{
		bottom:15px;right:15px;
	}
	.key01{
		.key-title-box{width: 96%;}
		.key-title{
			font-size: 2.4rem;margin-bottom: 10px;
		}
		.en{font-size: 1.0rem;margin-bottom: 30px;}
		
		.message{width: 100%;font-size: 1.0rem;letter-spacing: 0;
			//&:before,&:after{height: 90px;background-size:contain;}
		}
	}
	.key02{
		.key-title-box{
			bottom:40px;padding-left: 15px;
		}
		.key-title{font-size: 2.1rem;margin-bottom: 10px;}
		.message{
			font-size: 1.1rem;letter-spacing: 0;
		}
	}
}

.top-info{
	.title{
		font-size: 1.3rem;margin-top: 20px;
	}
	.info-message{
		li{font-size: 1.1rem;}
		.date{width: 100%;float: none;margin-bottom: 5px;}
		.text{padding-left: 0;}
	}
}

.top-mission{
	.title{font-size: 2.4rem;	}
	.en{font-size: 1.3rem;}
}

.top-about{
	background-size: 300px 480px;
	.block{padding: 100px 0;}
	.title{font-size: 2.1rem;	}
	.about-box{
		width: 100% !important;margin-bottom: 20px !important;
		a{padding: 20px 40px 20px 15px;
			&:before{right:13px;}
		}
		p{line-height: 1.5;}
		.subtitle{font-size: 1.8rem;margin-bottom: 5px;}
	}
}

.top-chamber{
	padding: 250px 0 40px;
	background: url(../../images/bg_chamber_sp.jpg) center top no-repeat;background-size:cover;
	.title-box{
		width: 95%;
	}
	.title{
		font-size: 2.1rem;margin-bottom: 15px;
		.s{font-size: 1.4rem;}
	}
	.text{
		margin-bottom: 15px;
	}
	.btn-area{text-align: center;}
}

.top-recruit{
	.block{
		padding: 100px 0;
	}
	.title{font-size: 2.4rem;text-align: center;}
	.lead{width: 100%;float: none;margin-right: 0;}
	.btn-area{text-align: center;}
	.recruit-list{
		.subtitle{font-size: 1.6rem;}
		dl{font-size: 1.1rem;}
		dt{width: 100%;float: none;}
		dd{padding-left: 0;margin-bottom: 10px;}
	}
}

#message{
	.message-title{
		font-size: 2.1rem;margin-bottom: 60px;
		br{display: none;}
	}
	.image{
		float: none;padding: 0;width: 60%;margin: 0 auto 60px;position: relative;
		img{margin-bottom: 0;}
		figcaption{background: #fff;position: absolute;bottom:0;right:0;padding: 5px 0 0 10px;}
		P{margin-bottom: 0;}
	}
	.signature{
		.post{font-size: 1.3rem;}
		.name{font-size: 1.8rem;}
	}
}
#aboutus{
	.gmap-wrapper{
   padding: 0 0 100%;
	}
	#history{
		.history-wrap{
			&:before{left:10px;}
		}
		.history-list{
			li{
				padding-left: 25px;
				&:before{
					width: 15px;height: 15px;left:2px;top:16px;
				}
				&:after{
					width: 7px;height: 7px;left:7px;top:21px;
				}
				&.last{padding-top: 15px;font-size: 1.6rem;}
			}
			.year{font-size: 3.6rem;}
		}
	}

}

#services{
	.image{margin: 0 -8% 20px 20px;}
	.number-title{font-size: 1.8rem;padding-left: 60px;
		.num{width: 60px;height: 60px;font-size: 4.0rem;left: -5px;bottom:-8px;line-height: 60px;}
	}
	.mission-wrap{
		padding: 30px 0;
	}
	.mission-title{line-height: 1.4;
		.s{display: block;}
	}
	.mission-tb{
		dt{float: none;margin-bottom: 5px;font-size: 1.4rem;padding: 10px 10px 10px 20px;width: auto;display: inline-block;}
		dd{padding-left: 0;}
	}
	#achieve{
		.chart-wrap{
			display: block;
		}
		.chart-box{
			width: 100%;
			h4{font-size: 1.5rem;}
		}
	}
}



#recruit{
	.recruit-list{
		th,td{padding: 25px 0;}
		th{font-size: 1.4rem;width: 52%;}
	}
	.recruit-type{
		justify-content: center;
		li{width: auto;padding: 0 6px;}
		a,.disabled{width: auto;padding: 0 15px;font-size: 1.2rem;height: 24px;line-height: 24px;border-radius: 12px;}
	}
	.recruit-title{
		font-size: 2.4rem;padding-top: 35px;padding-right: 0;margin-bottom: 35px;
	}
	.recruit-subtitle{
		font-size: 1.6rem;margin-bottom: 20px;
	}
	.type{
		font-size: 1.2rem;width: 60px;height: 24px;line-height: 24px;border-radius: 12px;margin-bottom: 35px;
		bottom:auto;top:0;right:auto;left:0;
	}
}

#contact{
	.form-box{
		margin: 0 -4%;padding: 25px 0 40px;
	}
	.contact-title{
		font-size: 2.4rem;margin-bottom: 60px;
		&:before{bottom:-20px;}
	}
	.contact-tb{margin-bottom: 0;
		tr{padding: 20px 5%;margin-bottom: 0;}
		th,td{padding: 0;}
		th{margin-bottom: 5px;}
		.require{float: none;margin-left: 10px;font-size: 1.0rem;}
	}
	
	.privacy-box{
		padding: 20px 5%;border: none;border-top: 1px dotted $BDC;font-size: 1.2rem;margin-bottom: 20px;
		h4{font-size: 1.4rem;}
	}
	.check-privacy{font-size: 1.3rem;}
	.thanks-box{
		padding: 40px 0;
	}
	.errormsg li{
		padding: 0 5%;
	}
}

#information{
	.info-tb{
		dt{float: none;width: 100%;padding-top: 20px;}
		dd{padding: 0 0 20px;}
	}
}

#privacy{
	.page-body{
		h3{font-size: 2.1rem;}
		h4{font-size: 1.7rem;}
	}
}

#sitemap{
	.sitemap-list{width: 100%;float: none;margin-bottom: 0;
		.first{padding-top: 15px;}
		&:nth-child(2){padding-top: 0;}
	}
}

#chamber{
	.function-wrap{
		display: block;
	}
	.function-box{
		width: 100%;font-size: 1.3rem;
		.number{font-size: 3.0rem;}
		&:nth-child(7){border-bottom: 1px solid $BDC;}
	}
	.doctor-anchor{padding: 15px 0;margin-bottom: 40px;
		li{line-height: 1.2;}
		.s{font-size: 1.0rem;}
		.initial{top:-5px;}
	}
	.doctor-box{
		.title{padding-left: 65px;}
		.name{font-size: 2.1rem;}
		.initial{font-size: 4.0rem;width: 50px;}
		.image{
			width: 100%;padding: 0 20%;margin-bottom: 20px;
		}
	}
	.doctor-tb{width: 100%;font-size: 1.2rem;
		th,td{padding: 0;}
		tbody th{border: none;}
	}
	
	.treatise-tb,.society-tb{
		thead{display: none;}
		tr{margin-bottom: 0;border: none;padding:25px 10px 15px 10px;position: relative;}
		.col1{display: block;color: #fff;background: $BLUE;padding: 4px 0;width: 40px;
			font-size: 1rem;line-height: 1;position: absolute;top:0;left:0;}
		.col3,.col4,.col5{width: 100%;font-size: 1.2rem;}
		.col2{font-weight: bold;color: $BLUE;line-height: 1.3;width: 100%;margin-bottom: 10px;}
	}
	.treatise-tb{
		.col3:before{content:"著者：";}
		.col4:before{content:"雑誌名：";}
	}
	.society-tb{
		.col3:before{content:"演者：";}
		.col4:before{content:"学会名：";}
		.col5:before{content:"発表年月：";}
	}
}