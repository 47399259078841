//tablet
p,figure{
	img{@include max(w);}
}
.fl-left,.fl-right{
	width: 30% ;min-width:30%;
}
.spdisp{display: block;}
.sphide{display: none;}

.block{
	width: 100%;padding: 60px 7% 0;
}

.page-body{
	h2 .s{font-size: 1.6rem;}
}

table{
	tbody th{width: 140px;}
}

.page-title-wrap{
	height: 200px;
	&:before{opacity: 0.5;}
	&:after{width: 100%;}
}

.page-title{
	left:6%;font-size: 3.2rem !important;
}

.breadcrumb{
	left:6%;
}

form{
	#sei,#mei,#seikana,#meikana{width: 120px;}
	#sei,#seikana{margin-right: 15px;}
	#tel input{width: 150px;}
}

.page-navi{
	width: 100%;
	.page-navi-list{
		li{
			&:last-child{border-right: none;}
		}
	}
}

#wrapper{
	min-width: 0;
}
header{
	
}


#menu-btn{
	display: block;width: 50px;height: 50px;
	position: fixed;right:15px;top:10px;cursor: pointer;z-index: 999;
	.menu-line{display: block;width: 30px;height: 2px;border-radius: 1px;
		background: $BLUE;position: absolute;left:calc(50% - 15px);
		&:nth-child(1){top:16px;transition: top 0.2s $ease2 0.3s,transform 0.3s $ease2 0s;}
		&:nth-child(2){top:24px;transition: opacity 0.1s $ease2 0.2s;}
		&:nth-child(3){top:32px;transition: top 0.2s $ease2 0.3s,transform 0.3s $ease2 0s;}
	}
}
.navi-wrap{
	visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
	background: transparent;position: fixed;z-index: 998;top:0;left:0;width: 100%;
	&:before{
		content:"";display: block;width: 100%;height: 100vh;position: absolute;top:0;left:0;z-index: 0;
		background: rgba(255,255,255,0.8);
	}
}
#gnavi{
	position: static;	padding: 80px 8% 0;
	ul{display: flex;flex-wrap: wrap;margin-bottom: 40px;}
	li{float: none;margin: 0;width: 50%;}
	a{font-size: 1.8rem;padding-left: 10px;}
	.banner{background: #fff;
		a{padding-left: 0;}
	}
}
.head-contact{
	display: none;
}
#menu.open{
	#menu-btn{
		.menu-line{
			&:nth-child(1){top:24px;transform: rotate(-135deg);transition: top 0.2s $ease2 0s,transform 0.3s $ease2 0.2s;}
			&:nth-child(2){opacity: 0;}
			&:nth-child(3){top:24px;transform: rotate(135deg);transition: top 0.2s $ease2 0s,transform 0.3s $ease2 0.2s;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}
footer{
	.banner{
		padding-bottom: 60px;
		ul{
			width: 100%;flex-wrap: wrap;justify-content: space-around;
		}
		li{
			width: 40%;text-align: center;margin-bottom: 20px;
		}
	}
	/*.foot-privacy{
		width: 100%;padding: 0 7%;
	}*/
	.footer-box{
		width: 100%;display: block;padding: 80px 5%;
	}
	.foot-address{
		width: 100%;border: none;padding: 0;position: relative;
	}
	.globalsign{
		position: absolute;right:0;top:0;
	}
	.foot-siteindex{
		display: none;
	}
	.foot-navi{
		text-align: center;float: none;margin-bottom: 20px;
		li{margin: 0 15px;}
	}
	.copyright{font-size: 1.0rem; text-align: center;display: block;width: 100%;float: none;}
}


/* sliderバージョン */
.key{
	.key02{
		.key-title-box{
			left:0;
		}
		&.show{
			.key-title-box{left:5%;}
		}
	}
}

.top-info{
	padding: 0 5% 10px;
	.block{
		display: block;
	}
	.info-message{padding:10px 0;width: 100%;}
}

.top-about{
	padding: 0 5%;
	.lead{
		width: 100%;
	}
	.about-list{
		justify-content: space-between;
	}
	.about-box{
		width: 48%;margin: 0 0 30px !important;
		&:nth-child(4),&:nth-child(5){width: 48%;margin: 0 0 30px;}
		a{height: 100%;}
		.subtitle{font-size: 2.1rem;}
		p{font-size: 1.4rem;}
	}
}

.top-chamber{
	padding: 100px 0;
	.title-box{
		margin-right: 0;width: 80%;padding: 30px 5%;
	}
	.title{
		font-size: 2.8rem;
		.s{font-size: 1.8rem;}
	}
}

.top-recruit{
	padding: 0 5%;
	.block{display: block;}
	.title-box{
		width: 100%;padding: 0;margin-bottom: 40px;overflow: hidden;
	}
	.lead{width: calc(100% - 240px);float: left;margin:0 40px 20px 0;}
	.recruit-list{
		width: 100%;
	}
}
#message{
	.message-title{font-size: 2.8rem;letter-spacing: 0.05em;padding-left: 15px;}
	.image{width: 40%;margin-left: 0;padding: 0 0 60px 40px;}
	.message-box{
		float: none;width: 100%;
	}

}
#aboutus{
	.train{
		dt{width: 100%;float: none;}
		dd{padding-left: 0;}
	}
}
#services{
	.image{width: 35%;float: right;margin: 0 0 40px 40px;}
	.mission-box{
		width: 100%;padding: 0 7%;
	}
}


#recruit{
	.recruit-list{
		th{font-size: 1.8rem;width: 50%;}
	}
	.recruit-type{
		a,.disabled{width: 100px;}
	}
	.recruit-title{padding-right: 105px;}
	.type{
		width: 100px;
	}
}

#contact{
	.form-box{
		padding: 30px 40px 40px;
	}
	.contact-tb{
		th{width: 170px;}
		.require{font-size: 1.3rem;}
	}
	.thanks-box{
		width: 100%;padding: 60px 0;
	}
}

#sitemap{
	.sitemap-list{
		.first{font-size: 1.6rem;}
		.second{font-size: 1.4rem;}
	}
}

#chamber{
	.doctor-anchor{
		li{position: relative;padding-left: 50px;min-height: 2.8em;line-height: 1.3;}
		.initial{
			position: absolute;left:0;top:0;
		}
	}
	.doctor-box{
		.image{width: 26%;
			img{@include max(w);}
		}
	}
	.doctor-tb{width: 70%;
		tbody th{width: 90px;}
	}
	
	.treatise-tb,.society-tb{
		.col1{white-space: nowrap;}
	}
	
}