//plugins

/*exValidation*/
.errMsg{margin-left:1em; padding-bottom:1px; display:block; line-height:1.4; text-indent:-.9em;}
.formError{
padding-top:5px; display:block;
	.formErrorContent{
		padding:3px 8px; width:auto; position:relative; color:#fff; background:$RED; font-size:1.2rem; _z-index:1; 
	}
	.formErrorArrow{display: none;}
	.fadeOut{opacity: 0.2;}
}
.err{background:#ffecec !important;}