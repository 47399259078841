@mixin fz($size: 24,$lh:$LINE_HEIGHT,$imp:0) {
	$base: 10;
	@if $imp==1{
		font-size: $size + px !important;  font-size: ($size / $base) * 1rem !important;
	}@else{
		font-size: $size + px;  font-size: ($size / $base) * 1rem;	
	}
	@if $lh !=$LINE_HEIGHT{
 		line-height:$lh;
 	}
}

@mixin center-box($w:0,$mb:0){
	width:$w;	margin:0 auto $mb;
}

@mixin last-mb0(){
	>:last-child{margin-bottom: 0;}
}

@mixin sprite($filename,$w,$h,$top){
	display: block;
	text-indent: -9999px;
	width: $w;
	height: $h;
	background:url(#{$img_common}#{$filename}) 0 #{-$top} no-repeat;
	&:hover{
		background-position: #{-$w} #{-$top};
	}
}
@mixin grad($tcolor:#666, $bcolor:#333){
  background-color: $tcolor;
  @include filter-gradient($tcolor, $bcolor, vertical);
  @include background-image(linear-gradient($tcolor 0%, $bcolor 100%));
}
@mixin grad-lighten($color:#666, $lighten:10%){
  background-color: $color;
  @include filter-gradient(lighten($color, $lighten), $color, vertical);
  @include background-image(linear-gradient(lighten($color, $lighten) 0%, $color 100%));
}

@mixin max($type){
	@if $type==w{
		width:100%; height:auto; 
	}@else if $type==h{
		width:auto; height:100%; 
	}
}

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}

@mixin hover {
  //hoverはPCのみ
  @media (hover: hover) and (pointer: fine)  {
    &:hover {
      @content;
    }
  }
}