//module
a{color:$BLUE;text-decoration: none;
	img{transition: opacity 0.3s $ease7;}
	@include hover{text-decoration: underline;
		img{opacity:0.6; }
	}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}

.arrow{
	display: inline-block;padding-left: 15px;position: relative;line-height: 1;
	&:before{
		font-size: 1.0rem;position: absolute;left:2px;top:50%;transform: translateY(-40%);
	}

}

.btn{
	display: inline-block;padding:12px 90px 12px 18px;line-height: 1;border: 1px solid $BLACK;
	color: $BLACK;position: relative;font-weight: bold;transition: all 0.4s $ease7;
	&:before{
		font-size: 1.6rem;position: absolute;right:12px;top:12px;font-weight: normal;
		transition: right 0.2s $ease1 0.05s;
	}
	@include hover{
		background: $BLACK;color: #fff;text-decoration: none;
		&:before{right:7px;}
	}
	&.btn-big{
		padding:18px 90px 18px 24px;font-size: 1.8rem;
		&:before{
			font-size: 1.8rem;right:18px;top:18px;
		}
		@include hover{
			&:before{right:13px;}
		}
	}
}



//フォントサイズ
.l{font-size:1.8rem;}
.s{font-size:1.4rem;}
.xs{font-size:1.2rem;}

//フォントカラー
.red{	color: $RED;}


.box{
	background-color: $BGC_BLUE;padding: 25px;margin-bottom: 30px;
	>*:last-child{margin-bottom: 0 !important;}
}

.spdisp{display: none;}

.block{
	width: 960px;margin: 0 auto 80px;padding-top: 60px;
}

.page-body{

	h2{
		@extend .shingoR;font-size: 2.8rem;line-height: 1.2;color: $BLUE;margin-bottom: 25px;padding-top: 25px;
		.s{font-size: 1.8rem;}
	}
	h3{
		font-size: 2.1rem;line-height: 1.2;border-bottom: 1px solid $BLUE;color: $BLACK;font-weight: bold;margin-bottom: 25px;padding-bottom: 5px;padding-top: 15px;
	}
	h4{
		font-size: 1.8rem;line-height: 1.2;font-weight: bold;margin-bottom: 15px;
	}
	h5,h6{
		font-weight: bold;margin-bottom: 10px;
	}

	p,ul,ol,dl{margin-bottom: 30px;}
	li{line-height: 1.5;margin-bottom: 8px;
		&:last-child{margin-bottom: 0 !important;}
	}
	ul{
		li{padding-left: 20px;position: relative;
			&:before{content:"";width: 8px;height: 8px;display: block;border-radius:4px;background: $BLUE;
				position: absolute;left:2px;top:8px;
			}
		}
	}
}



//表組み
table{width: 100%;font-size: 1.6rem;line-height: 1.5;margin-bottom: 50px;}
th,td{padding:20px 5px;border-bottom: 1px solid $BDC;vertical-align: top;}
thead th{	font-weight: bold; text-align: center;}
tbody th{ width: 15%;font-weight: bold;color: $NAVY;border-color: $NAVY;}

//罫線
hr{	border: none;border-bottom: 5px double $BDC;padding-top:80px;margin-bottom: 80px;}

//ページタイトル
.page-title-wrap{
	height: 240px;position: relative;
	background-repeat: no-repeat;
	&:before{
		content:"";width: 60%;height: 100%;background: linear-gradient(115deg,#00a6d6 0%,$BLUE 80%,$BLUE 100%);transform: skewX(-15deg);
		position: absolute;left:-35px;top:0;z-index: 1;
	}
	&:after{
		content:"";width: 50%;height: 100%;position: absolute;top:0;right:0;
		background-size: cover;background-position: right center;
	}
}
.page-title{
	@extend .shingoM;color: #fff;font-size: 4.0rem;position: absolute;left:calc((100% - 960px) / 2);top:60px;z-index: 2;
	letter-spacing: 0.1em;line-height: 1.2;
}
#message{
	.page-title-wrap:after{background-image: url(../../message/images/bg_title_message.jpg);}
}
#aboutus{
	.page-title-wrap:after{background-image: url(../../aboutus/images/bg_title_aboutus.jpg);}
}
#history{
	.page-title-wrap:after{background-image: url(../../history/images/bg_title_history.jpg);}
}
#services{
	.page-title-wrap:after{background-image: url(../../services/images/bg_title_services.jpg);}
}
#achieve{
	.page-title-wrap:after{background-image: url(../../achieve/images/bg_title_achieve.jpg);}
}
#recruit{
	.page-title-wrap:after{background-image: url(../../recruit/images/bg_title_recruit.jpg);}
}
#contact{
	.page-title-wrap:after{background-image: url(../../contact/images/bg_title_contact.jpg);}
}
#privacy{
	.page-title-wrap:after{background-image: url(../../privacy/images/bg_title_privacy.jpg);}
}
#information{
	.page-title-wrap:after{background-image: url(../../information/images/bg_title_information.jpg);}
}
#sitemap{
	.page-title-wrap:after{background-image: url(../../sitemap/images/bg_title_sitemap.png);}
}

#introdution{
	.page-title-wrap:after{background-image: url(../../introdution/images/bg_title_introdution.jpg);}
	.page-title{top:50px;font-size: 3.8rem;}	
}
#chamber{
	.page-title-wrap:after{background-image: url(../../chamber/images/bg_title_chamber.jpg);}
}
//パンくずリスト
.breadcrumb{font-size:1rem;position: absolute;left:calc((100% - 960px) / 2);bottom:20px;z-index: 2;
	li{display: inline-block;color: #fff;
		&:before{content:"/";display: inline-block;margin: 0 10px 0 5px;color: $BGC_BLUE;}
		&:first-child:before{display: none;}
	}
	a{color: rgba(255,255,255,0.8);}
}
//ページネーション
.paginate{padding-top: 60px;
	ul{display: flex;justify-content: center;
		li{margin: 0 5px;padding-left: 0;
			&:before{display: none;}
		}
	}
	a,span{display: block;padding: 5px 3px 6px;min-width: 28px;text-align: center;line-height: 1;}
	a{border: 1px solid $BLUE;
		@include hover{
			background: $BLUE;color: #fff;text-decoration: none;
		}
	}
	span{border: 1px solid $BLACK;}

	.disabled{color: #ccc;}
	.fs-current{
		a{cursor:default;color: $FONT_COLOR;
			@include hover{background: none;text-decoration: none;}
		}
	}
	.prev,.next{
		a,span{border: none;}
		a:hover{background: none;color: $BLUE;}
	}
}

.page-navi{
	width: 960px;margin: 0 auto;
	.page-navi-list{
		display: flex;justify-content: flex-end;font-size: 1.4rem;
		li{
			border-bottom: 1px solid $BDC;padding: 0 12px;
			&:first-child{border-left: 1px solid $BDC;padding-left:30px;}
			&:last-child{border-right: 1px solid $BDC;padding-right: 30px;}
		}
		a{display: inline-block;padding: 15px 0;border-bottom: 2px solid transparent;position: relative;bottom:-1px;
			color: $FONT-COLOR;transition:border 0.3s $ease7,color 0.3s $ease7;font-weight: bold;
		}
		.active a,a:hover{
			color: $BLUE;border-color: $BLUE;text-decoration: none;
		}
	}
}