//page

//トップページ
#home{


}
.key{
	width: 100%;height: 600px;background: #ebf6fa;position: relative;overflow: hidden;
	
	.bx-wrapper,.bx-viewport,.slide-body,.slide-body li{height: 100% !important;}
	.bx-pager{
		position: absolute;bottom:24px;right:25px;line-height: 1;z-index: 2;
	}
	.bx-pager-item{
		display: inline-block;text-indent: -9999px;margin-left: 20px;
	}
	.bx-pager-link{
		position: relative;cursor: pointer;width: 40px;height: 16px;background: #d8d8d8;display: block;
		&:before,&:after{content:"";display: block;position:absolute;	width: 0;height: 0;border-style: solid;	top:0;}
		&:before{
			border-width: 8px 6px 8px 0;border-color: transparent #d8d8d8 transparent transparent;
			left:-6px;
		}
		&:after{
			border-width: 8px 0 8px 6px;border-color: transparent transparent transparent #d8d8d8 ;
			right:-6px;
		}
		&.active{
			background: $BLUE;
			&:before{border-right-color: $BLUE;}
			&:after{border-left-color: $BLUE;}
		}
		@include hover{
			background: $BLUE;
			&:before{border-right-color: $BLUE;}
			&:after{border-left-color: $BLUE;}
		}
	}
	.key-box{
		overflow: hidden;width:100%;float: left;
	}
	.key01{
		background: url(../../images/key01.jpg) center center no-repeat;background-size:cover;opacity: 0;
		transition: opacity 0.8s $ease5 0.2s;
		
		.key-title-box{
			text-align: center;	position: absolute;z-index: 3;top:50%;left:50%;transform: translate3d(-50%,-50%,3px);
			opacity: 0;transition: opacity 0.8s $ease5 1.2s;
		}
		.key-title{
			font-family: 'Sawarabi Mincho', serif;;font-size: 4.1rem;margin-bottom: 15px;letter-spacing: 0.15em;text-indent: 0.15em;color: $NAVY;
		}
		.en{font-family: Georgia, Times, Times New Roman, serif;font-size: 1.7rem;margin-bottom: 45px;color: $NAVY;}
		.message{
			@extend .shingoR;font-size: 1.5rem;width: 580px;position: relative;
		}
		&.show{
			opacity: 1;
			.key-title-box{opacity: 1;}
		}
	}
	.key02{
		background: url(../../images/key02.jpg) center center no-repeat;background-size:cover;
		&:before{
			content:"";width: 100%;height: 100%;background: url(../../images/key02_cover.png) center center no-repeat;background-size:cover;
		position: absolute;top:0;left:0;z-index: 2;opacity: 0;transform:scale(1.5);
		transition: opacity 1.2s $ease5 0.4s,transform 0.5s $ease5 0.4s;
		}
		.key-title-box{
			position: absolute;bottom:80px;left:10%;border-left: 2px solid #fff;padding-left: 20px;color: #fff;opacity: 0;
		transition: all 0.8s $ease5 1s;z-index: 3;
		}
		.key-title{
			@extend .shingoM;font-size: 3.0rem;line-height: 1.2;margin-bottom: 20px;
		}
		.message{
			@extend .shingoR;font-size: 1.6rem;line-height: 1.5;
		}
		&.show{
			&:before{opacity: 1;transform: scale(1);}
			.key-title-box{opacity: 1;left:15%;}
		}
	}
}
.top-info{
	width: 100%;background: #fff;
	.block{
		padding: 0;margin-bottom: 0;display: flex;
	}
	.title{width: 82px;	margin-top: 28px;
		a{color: #fff;display: block;line-height: 1;height: 26px;	border-radius:13px;
			text-align: center;font-size: 1.4rem;padding-top: 5px;font-weight: bold;background: $NAVY;
			transition: opacity 0.3s $ease7;
			@include hover{opacity: 0.6;text-decoration: none;}
		}
	}
	.info-message{
		box-sizing: content-box; padding: 30px 20px;position: relative;overflow: hidden;width: calc(100% - 82px);
		ul{width: 100%; position: relative;}
		li{width: 100%;font-size: 1.4rem; line-height: 1.3;display: none;overflow: hidden;}
		a{color: inherit;}
		.date{color: $YELLOW;display: block;width: 95px;float: left;}
		.text{display: block;padding-left: 95px;}
	}
	
}

.top-mission{
	width: 100%;background: url(../../images/bg_top_mission.jpg) center center no-repeat;	background-size: cover;
	padding: 100px 0 125px;text-align: center;position: relative;
	&:before{content:"";width: 1px;height: 70px;background: $BLUE;position: absolute;bottom:-35px;left:50%;z-index:10;}
	.title{
		@extend .shingoM;font-size: 3.2rem;line-height: 1.3;letter-spacing: 0.15em;text-indent: 0.15em;margin-bottom: 15px;
		color: $BLACK;
	}
	.en{@extend .lato;font-size: 1.5rem;line-height: 1;letter-spacing: 0.15em;text-indent: 0.15em;margin-bottom: 60px;}
}
.top-about{
	width: 100%;background: url(../../images/bg_top_about.jpg) right bottom no-repeat;	background-size: 500px 800px;position: relative;
	&:before{content:"";width: 1px;height: 70px;background: $BLUE;position: absolute;bottom:-35px;left:50%;z-index:10;}
	.block{
		padding: 125px 0;margin-bottom: 0;
	}
	.title{
		text-align: center;@extend .shingoM;font-size: 3.2rem;line-height: 1.3;color: $BLACK;margin-bottom: 25px;
	}
	.lead{
		width: 650px; margin: 0 auto 40px;
	}
	.about-list{
		display: flex;flex-wrap: wrap;justify-content: center;
	}
	.about-box{
		width: 300px;
		a{display: block;border: 1px solid $BLUE;color: inherit;padding: 40px 45px 40px 25px;position: relative;transition: all 0.4s $ease7;
			&:before{position: absolute;top:calc(50% - 0.5em);right:17px;font-size: 2.4rem;color: $BLUE;transition: right 0.2s $ease1 0.05s;}
			@include hover{
				text-decoration: none;background: $BGC_BLUE;
				&:before{right:12px;}
			}
		}
		&:nth-child(2){margin: 0 30px 30px;}
		&:nth-child(4),&:nth-child(5){width: 350px;margin: 0 50px 60px;}
		.subtitle{
			font-size: 2.4rem;@extend .shingoR;margin-bottom: 10px;color: $BLUE;
		}
	}
	a{background: #fff;display: block;}
}

.top-chamber{
	background: url(../../images/bg_chamber.jpg) center center no-repeat;background-size:cover;padding: 180px 0;position: relative;
	@extend .cf;
	&:before{content:"";width: 1px;height: 70px;background: $BLUE;position: absolute;bottom:-35px;left:50%;z-index:10;}
	.title-box{
		float: right;margin-right:10%;padding: 40px 3%;width: 550px;
		background: rgba(0,94,181,.8);color: #fff;
	}
	.title{
		@extend .shingoM;font-size: 3.1rem;line-height: 1.3;color: $BLACK;margin-bottom: 25px;color: #fff;
		.s{font-size: 2.1rem;display: block;}
	}
	.text{
		margin-bottom: 32px;
	}
	.btn{
		color: #fff;border-color:#fff;
		@include hover{
			color: $BLUE;background: #fff;
		}
	}
}
.top-recruit{
	width: 100%;background: $BGC_BLUE;position: relative;
	&:before{content:"";width: 1px;height: 70px;background: $BLUE;position: absolute;bottom:-35px;left:50%;z-index:10;}
	.block{
		padding: 125px 0;margin-bottom: 0;display: flex;justify-content: space-between
	}
	.title-box{
		width: 480px;padding: 0 25px;
	}
	.title{
		@extend .shingoM;font-size: 3.2rem;line-height: 1.3;color: $BLACK;margin-bottom: 25px;
	}
	.lead{margin-bottom: 50px;}
	.recruit-list{
		width: 440px;background: #fff;padding: 20px 25px;margin-top: 10px;
		.subtitle{
			font-size: 1.8rem;margin-bottom: 15px;font-weight: bold;
		}
		dl{font-size: 1.4rem;line-height: 1.3;}
		dt{
			float: left;width: 90px;color: $YELLOW;
		}
		dd{
			padding-left: 90px;margin-bottom: 15px;
		}
		a{color: $FONT_COLOR;}
	}
}
#message{
	.block{overflow: hidden;}
	.message-title{
		font-size: 3.2rem;line-height: 1.5;margin-bottom: 80px;letter-spacing: 0.1em;padding-left: 25px;border-left: 2px solid $BLUE;
		padding-top: 0;
	}
	//.image{float: right;width: 300px;margin-left: 60px;background: #fff;text-align: center;}
	.message-box{
		//width: 600px;float: left;
	}
	.signature{
		color: $BLACK;text-align: right;
		.post{font-size: 	1.5rem;}
		.name{font-weight: bold;font-size: 2.1rem;line-height: 1.2;letter-spacing: 0.15em;}
	}
	
}
#aboutus{
	.gmap-wrapper{
    max-width: 100%;height: 0;margin: 0 0 30px;	padding: 0 0 60%;overflow: hidden;position: relative;top:0;left: 0;border: 1px solid $NAVY;
	}
	#gmap{position: absolute;top:0;left:0;width: 100%;height: 100%;margin: 0;padding: 0;
		img{width: auto;height: auto;min-width: 0;}
	}
	#infowin{	margin-bottom: 0 !important;font-family:$FONT_FAMILY;
		dt{color: $BLUE;font-size: 1.3rem	;}
		dd{text-align: left;font-size: 1.0rem;color: $FONT_COLOR;}
	}
	.train{
		dt{width: 170px;font-weight: bold;float: left;}
		dd{padding-left: 170px;margin-bottom:10px;}
	}
	#history{
		.history-wrap{
			position: relative;
			&:before{
				content:"";width: 1px;height: 100%;background: $BGC_BLUE;
				position: absolute;left:15px;top:0;z-index: 0;
			}
		}
		.history-list{
			li{margin-bottom: 30px;position: relative;z-index: 1;padding-left: 40px;line-height: 1.3;
				&:before{
					content:"";width: 20px;height: 20px;border: 1px solid $BLUE;border-radius:50% ;
					position: absolute;left:4px;top:16px;z-index: 2;opacity: 0.7;background: transparent;
				}
				&:after{
					content:"";width: 10px;height: 10px;border-radius:50% ;background: $GREEN;
					position: absolute;left:10px;top:22px;z-index: 2;opacity: 0.8;
				}
				&.last{
					font-size: 1.8rem;padding-top: 15px;
				}
			}
			.year{
				font-size: 4.0rem;display: block;color: $BLUE;letter-spacing: 0;
			}
		}
		.step{
			padding-top: 30px;
		}
	}
	
}

#services{
	.image{width: 240px;float: right;margin: 0 0 40px 40px;}
	.number-title{position: relative;padding-left: 80px;padding-top: 50px;
		.num{
			display: block;font-family: "新ゴ EL";font-size: 4.5rem;text-align: center;line-height: 68px;
			position: absolute;left:0;bottom:-10px;border-radius: 50%;width: 70px;height: 70px;border: 1px solid $BLUE;color: $BLUE;
		}
	}
	.mission-wrap{
		background: url(../../services/images/bg_mission.jpg) center center no-repeat;background-size:cover;padding: 50px 0;
	}
	.mission-box{
		width: 960px;margin: 0 auto;
	}
	.mission-tb{
		dt{
			width: 200px;background: $BLUE;font-size: 1.7rem;line-height: 1;font-weight: bold;color: #fff;float: left;
			border-radius: 3px;padding: 18px 20px;position: relative;
			&:before{
				content:"";width: 2px;height: 20px;border-radius: 1px;background: #fff;position: absolute;left:10px;top:calc(50% - 10px);
			}
		}
		dd{padding-left: 220px;margin-bottom: 20px;}
		.dd4{padding-top: 0.75em;}
	}
	
	#achieve{
		.block{margin-bottom: 0;}
		.chart-wrap{
			width: 100%;display: flex;justify-content: space-between;
		}
		.chart-box{
			width: 48%;
			h4{font-size: 1.8rem;}
		}
		.chart-container{
			position: relative;
		}
		#chart1,#chart2{}
	}
	
}
.win #services{
	h4 .num{line-height: 60px;}
}


#recruit{
	.recruit-list{
		th,td{
			border-bottom: 1px dotted $BDC;padding: 25px 5px;vertical-align: middle;
		}
		th{width: 500px;font-size: 2.0rem;}
	}
	.recruit-type{
		display: flex;margin-bottom: 0;
		li{width: 50%;border-left: 1px solid $BDC;text-align: center;padding-left: 0;
			&:first-child{border: none;}
			&:before{display: none;}
		}
		a,.disabled{display: inline-block;width: 120px;color: #fff;height: 30px;line-height: 30px;border-radius:15px; }
		a{background: $BLUE;transition: opacity 0.3s $ease7;
			@include hover{
				opacity: 0.6;text-decoration: none;
			}
		}
		.part a{background: $GREEN;}
		.disabled{background: #aaa;}
	}
	.recruit-title{
		position: relative;font-size: 3.2rem;margin-bottom: 50px;padding-right: 125px;
		@extend .shingoR; color: $BLUE;
	}
	.recruit-subtitle{
		font-size: 2.1rem;
		border: none;margin-bottom: 0;background: $NAVY;color: #fff;padding: 5px;
	}
	.type{
		font-size: 1.8rem;width: 120px;height: 36px;line-height: 36px;border-radius:18px;position: absolute;right:0;bottom:0;
		background: $BLUE;color: #fff;text-align: center;
		&.part{background: $GREEN;}
	}
	.recruit-tb{
		margin-bottom: 10px;
	}
	.btn-area{text-align: center;}
}

#contact{
	.page-body{
		background: #e6f1f9;padding-bottom: 20px;
	}
	.form-box{
		background: #fff;padding: 30px 60px 60px;border-radius: 5px;margin-bottom: 60px;
	}
	.contact-title{
		font-size: 3.2rem;text-align: center;margin-bottom: 80px;position: relative;
		&:before{content:"";width: 80px;height: 4px;border-radius: 2px;
			position: absolute;left:calc(50% - 40px);bottom:-35px;background: $BGC_BLUE;
		}
	}
	.contact-tb{margin-bottom: 20px;
		th,td{border: none;padding-bottom: 30px;}
		th{width: 240px;padding-left: 0;padding-top: 25px;}
		td{padding-left: 30px;}
		.require{display: inline-block;float: right;color: $RED;font-weight: normal;font-size: 1.4rem;}
	}
	.privacy-box{
		background: #fff;border: 1px solid $BDC;font-size: 1.4rem;
		h4{font-size: 1.8rem;margin-bottom: 15px;padding-top: 0;}
		.ssl{
			overflow: hidden;
			p{margin-bottom: 0;}
			.globalsign{width: 115px;margin-right: 20px;float: left;}
		}
	}
	.check-privacy{text-align: center;margin-bottom: 30px;}
	
	.thanks-box{
		width: 700px;background: #fff;padding: 60px;border-radius: 5px;margin:0 auto 60px;
		text-align: center;
	}
}

#information{
	.info-tb{
		dt{color: $YELLOW;width: 120px;float: left;padding-top: 30px;}
		dd{padding: 30px 0 30px 120px;border-bottom: 1px dotted $BDC;}
	}
	.page-body{
		.date{color: $YELLOW;}
		.date + .info-detail-title{padding-top: 10px;margin-bottom: 60px;}
		.info-detail-title{
			@extend .shingoR;font-size: 2.8rem;line-height: 1.2;color: $BLUE;
		}
		h2{
			color: $BLACK; font-size: 2.1rem;
		}
	}
}


#privacy{
	.block{
		margin-bottom: 20px;
	}
	.page-body{
		h3{font-size: 2.4rem;}
		ol li{margin-bottom: 30px;}
			
	}
	
}

#sitemap{
	.sitemap-list{
		width: 50%;float: left;
		li{
			padding-left: 0;
			&:before{display: none;}
		}
		.first{font-size: 1.8rem;padding-top: 30px;font-weight: bold;}
		.second{padding-top: 5px;}
		&:nth-child(2){
			padding-top: 60px;
		}
	}
}

#chamber{
	.function-wrap{
		display: flex;flex-wrap:wrap;justify-content: space-between;
	}
	.function-box{
		text-align: center;width: 46%;border-bottom: 1px solid $BDC;margin-bottom: 15px;
		.number{@extend .shingoM;font-size: 4.0rem;color: $BLUE;margin-bottom: 10px;}
		&:nth-child(7),&:nth-child(8){border: none;}
	}
	
	.doctor-anchor{
		padding: 25px 20px;margin-bottom: 80px;
		li{padding-left: 0;line-height: 1;margin-bottom: 15px;
			&:before{display: none;}
			&:last-child{margin-bottom: 0;}
		}
	}
	.initial{
		font-size: 3.6rem;vertical-align: middle;color: $BLUE;display: inline-block;width: 40px;text-align: center;
		@extend .shingoM;margin-right: 10px;
	}
	
	.doctor-list{
		
	}
	.doctor-box{
		overflow: hidden;margin-bottom: 80px;
		.title{
			position: relative;padding-left: 85px;padding-top: 15px;
			line-height: 1.4;
		}
		.name{font-size: 3.2rem;color:$BLACK;}	
		.initial{position: absolute;left:0;top:15px;font-size: 5.0rem;width: 60px;padding-right: 10px;border-right: 1px solid $BDC;}
		.image{
			width: 200px;float: left;padding-top: 15px;
		}

	}
	.doctor-tb{font-size: 1.4rem;width: calc(100% - 260px);float: right;margin-bottom: 0;
		th,td{padding: 15px 0;}
		tbody th{border-bottom: 1px solid $BDC;}
	}
	
	.treatise-tb{font-size: 1.4rem;letter-spacing: 0;
		thead{
			background: $BLUE;
			th{border-left: 1px solid #fff;color: #fff;}
		}
		th,td{border: none;}
		tbody{
			tr:nth-child(2n){background: $BGC_GRAY;}
		}
		.col1{width: 50px;text-align: center;padding: 20px 0;}
		.col2{width: 430px;color: $BLACK;font-weight: normal;}
		.col3{width: 300px;}
		.col4{width: 180px;}
	}
	.society-tb{font-size: 1.4rem;letter-spacing: 0;
		thead{
			background: $BLUE;
			th{border-left: 1px solid #fff;color: #fff;}
		}
		th,td{border: none;}
		tbody{
			tr:nth-child(2n){background: $BGC_GRAY;}
		}
		.col1{width: 50px;text-align: center;padding: 20px 0;}
		.col2{width: 420px;color: $BLACK;font-weight: normal;}
		.col3{width: 250px;}
		.col4{width: 150px;}
		.col5{width: 90px;padding-right: 0;}
	}
}
