//small pc
@media screen and (max-width: 1200px) {
	
	header{
		.logo{padding-top: 20px;
			img{width: 300px;height: auto;}
		}
	}
	.head-contact{
		a{
			font-size: 1.4rem;padding: 0 13px 0 40px;
			&:before{font-size: 2.4rem;left:13px;}
		}
	}
	#menu{
		width: calc(100% - 320px);
	}
	#gnavi{
		right:140px;
		li{margin-right: 22px;}
	}
}