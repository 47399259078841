//base
@import url('https://fonts.googleapis.com/css?family=Lato|Sawarabi+Mincho&subset=japanese');
@font-face {
  font-family: "YakuHanJPBold";
  font-weight: 400;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"),
  url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: 'iconfont';
  src:
    url('../fonts/iconfont.ttf?dhr1rq') format('truetype'),
    url('../fonts/iconfont.woff?dhr1rq') format('woff'),
    url('../fonts/iconfont.svg?dhr1rq#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-mail:before {
  content: "\e901";
}
.icon-gmap:before {
  content: "\e902";
}


*{box-sizing:border-box; word-break: break-all;}
html{
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}

img{vertical-align: bottom;}
ol{padding-left:20px; list-style-type:decimal;}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:1.6rem;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.05em;
}

.shingoM{font-family: YakuHanJPBold,"UD新ゴ M";}
.shingoR{font-family: YakuHanJP,"UD新ゴ R";}
.lato{font-family: 'Lato', sans-serif;}