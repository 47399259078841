//form
input[type="button"],input[type="submit"]{
	-webkit-appearance: none; border: none; margin: 0; border-radius:0;cursor: pointer;font-family:$FONT_FAMILY;
}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{
	-webkit-appearance: none; width:100%;	border: 1px solid $BDC;	padding:4px 8px; border-radius:1px;
	background: transparent;font-family:$FONT_FAMILY;font-size: 1.6rem;
}
:placeholder-shown {color: #bbb; }
::-webkit-input-placeholder {color:#bbb; }
::-moz-placeholder {color: #bbb; opacity: 1; }
:-ms-input-placeholder {color:#bbb; }

form{
	#sei,#mei,#seikana,#meikana{display: inline-block;width: 150px;}
	#sei,#seikana{margin-right:25px;}
	#tel input{width: 240px;margin-right: 20px;}
	#ckemail{margin-top: 18px;}
	#comment{height: 200px;}
	#type{
		label{display: inline-block;margin-bottom: 10px;line-height: 1;}
		input{margin-right: 5px;}
	}
	.input-box{position: relative;}
	#btn-submit{
		display: block;font-size: 1.8rem;color: $BLUE;border: 1px solid $BLUE;width: 500px;margin: 0 auto;padding: 20px 0;line-height: 1;
		background: #fff;font-weight: bold;transition: all 0.3s $ease7;
		@include hover{
			background: $BLUE;color: #fff;
		}
		&:disabled{
			background: $BGC_GRAY;color: $GRAY;border-color: $GRAY;cursor: default;
		}
	}
	#back{
		display: block;font-size: 1.5rem;color: $BLACK;border: 1px solid $BLACK;width: 300px;margin: 20px auto 0;padding: 15px 0;line-height: 1;
		background: #fff;transition: all 0.3s $ease7;font-weight: bold;
		@include hover{
			background: $BLACK;color: #fff;
		}
	}
}
.mac{
	form{
		#type{
			input[type="radio"]{vertical-align:0.2em;}
		}
	}
}